import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {ChannelType, Connection, LIMITS} from "../../types/types";
import {setNewConnectionId} from "../../../redux/slices/connections-slice";
import {
    clientWsAvitoChannel,
    clientWsChannel,
    clientWsTelegramChannel,
    clientWsVkChannel,
    clientWsWhatsappChannel
} from "../../../api/client-ws";
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";
import InputField, {InputType} from "../../atom/InputField";

interface ButtonStepProps {
    buttonName?: string;
}

const ButtonStep: React.FC<ButtonStepProps> = ({buttonName}) => {
    const selectedMessenger = useSelector((state: RootState) => state.connections.selectedMessenger);
    const dispatch: AppDispatch = useDispatch();
    const [vkToken, setVKToken] = useState('');
    const [connectionsCount, setConnectionsCount] = useState<number>(0);

    const MAX_CONNECTIONS = LIMITS[selectedMessenger as ChannelType.WHATSAPP | ChannelType.TELEGRAM | ChannelType.AVITO];

    useEffect(() => {
        const loadUsersChannels = async () => {
            try {
                const response = await clientWsChannel.getAll();
                const filteredConnections = (response.data as Connection[]).filter(connection => connection.channelType === selectedMessenger);
                setConnectionsCount(filteredConnections.length);
            } catch (error) {
                console.error('Failed to load users channels', error);
            }
        };

        loadUsersChannels();
    }, [selectedMessenger]);

    // Изменение контакта
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if (name === 'VK') setVKToken(value.trim());
    };

    const createNewChannel = async () => {
        try {
            let response;
            switch (selectedMessenger) {
                case ChannelType.WHATSAPP:
                    response = await clientWsWhatsappChannel.create();
                    break;
                case ChannelType.TELEGRAM:
                    response = await clientWsTelegramChannel.create();
                    break;
                case ChannelType.AVITO:
                    response = await clientWsAvitoChannel.create();
                    break;
                case ChannelType.VK:
                    response = await clientWsVkChannel.create(vkToken);
                    break;
                default:
                    console.error('Unsupported messenger type');
                    return;
            }
            dispatch(setNewConnectionId(response.data.id));
        } catch (error) {
            console.error(`Error creating ${selectedMessenger} channel:`, error);
        }
    };


    const renderMessage = () => {
        if (connectionsCount >= MAX_CONNECTIONS) {
            return <p className="text-red-500">Вы достигли максимального количества каналов для этого мессенджера.
                Нельзя создать больше каналов.</p>;
        } else {
            return (
                <>
                    <p className="text-lg mb-5">Вам доступно {MAX_CONNECTIONS - connectionsCount} подключение(ий).</p>
                    {ChannelType.VK &&
                        <InputField type={InputType.TEXT} name={`VK`} placeholder={`vk-token`} value={vkToken}
                                    onChange={handleInputChange}
                                    customClass={`mb-2`}/>}
                    <Button title={buttonName ? buttonName : "Создать подключение"} onClick={createNewChannel}
                            buttonColor={ButtonColor.GREEN}
                            buttonSize={ButtonSize.FULL} customClass={`shadow`}/>
                </>
            )
        }
    };

    return (
        <div>
            {renderMessage()}
        </div>
    );
};

export default ButtonStep;

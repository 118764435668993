import React from 'react';
import {FaIndent, FaOutdent} from "react-icons/fa6";
import {useConvertToSubBlock} from "../../../hooks/keeps/useConvertToSubBlock";
import {useConvertSubBlockToBlock} from "../../../hooks/keeps/useConvertSubBlockToBlock";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";

const MobileBlockMenu: React.FC = () => {
    const currentBlock = useSelector((state: RootState) => state.keeps.present.currentBlock);

    const convertToSubBlock = useConvertToSubBlock();
    const convertToBlock = useConvertSubBlockToBlock();

    function handleOutdentClick() {
        if (currentBlock) {
            convertToBlock(structuredClone(currentBlock));
        }
    }

    function handleIndentClick() {
        if (currentBlock) {
            convertToSubBlock(structuredClone(currentBlock));
        }
    }

    return (
        <>
            {currentBlock ? (
                <div className={`w-full h-10 bottom-0 fixed z-50 bg-white flex items-center space-x-1 p-2`}>
                    <FaOutdent onClick={handleOutdentClick}/>
                    <FaIndent onClick={handleIndentClick}/>
                </div>
            ) : null}
        </>
    );
};

export default MobileBlockMenu;
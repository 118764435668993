import {useCallback} from "react";
import {v4 as uuidv4} from "uuid";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {BlockType, CustomNode, NodeType} from "../../components/types/types";
import {setCurrentBlock, setFirstPastedBlock, setGrid} from "../../redux/slices/keeps-slice";

export const useHandlePaste = () => {
    const dispatch = useDispatch();

    const currentBlock = useSelector((state: RootState) => state.keeps.present.currentBlock);
    const currentPage = useSelector((state: RootState) => state.keeps.present.currentPage);
    const cursorPosition = useSelector((state: RootState) => state.keeps.present.position);
    const grid = useSelector((state: RootState) => state.keeps.present.grid);

    const transformTextToCustomNodes = useCallback(
        (text: string): CustomNode[] => {
            if (!currentPage?.id) {
                console.error("Page ID is undefined");
                return [];
            }

            const lines = text.split("\n");
            const rootNodes: CustomNode[] = [];
            const nodeStack: { node: CustomNode; indent: number }[] = [];
            const currentText = currentBlock?.text || "";
            const beforeCursor = cursorPosition !== null ? currentText.slice(0, cursorPosition - 1) : "";
            const afterCursor = cursorPosition !== null ? currentText.slice(cursorPosition - 1) : "";

            let currentBlockParentId = currentBlock?.parentId || null;
            let currentNodeLocal: CustomNode | null = null;

            for (const line of lines) {
                const trimmedLine = line.trim();
                const indent = line.length - trimmedLine.length;

                if (trimmedLine === "") {
                    currentNodeLocal = null;
                    continue;
                }

                const newNode: CustomNode = {
                    id: uuidv4(),
                    pageId: currentPage.id,
                    nodeType: NodeType.BLOCK,
                    blockType: (() => {
                        if (trimmedLine.startsWith(">[]")) {
                            return BlockType.toggled_checkbox;
                        } else if (trimmedLine.startsWith(">")) {
                            return BlockType.toggle;
                        } else if (trimmedLine.startsWith("[]") || trimmedLine.startsWith("[*]")) {
                            return BlockType.checkbox;
                        }
                        return BlockType.text;
                    })(),
                    text: trimmedLine.replace(/^(>\[]|>|\[\*]|\[])/, "").trim(),
                    parentId: null,
                    children: [],
                };


                if (trimmedLine.startsWith("[*]")) {
                    newNode.checked = true;
                }

                if (currentNodeLocal && !line.startsWith("\t")) {
                    currentNodeLocal.text += " " + trimmedLine;
                    continue;
                }

                while (nodeStack.length > 0 && nodeStack[nodeStack.length - 1].indent >= indent) {
                    nodeStack.pop();
                }

                if (nodeStack.length > 0) {
                    const parent = nodeStack[nodeStack.length - 1].node;
                    parent.children.push(newNode);
                    newNode.parentId = parent.id;
                } else {
                    newNode.parentId = currentBlockParentId;
                    rootNodes.push(newNode);
                }

                nodeStack.push({node: newNode, indent});
                currentNodeLocal = newNode;
            }

            if (rootNodes.length > 0) {
                rootNodes[0].id = currentBlock?.id || uuidv4();
                rootNodes[0].text = `${beforeCursor}${rootNodes[0].text || ""}`.trim();

                const lastNode = rootNodes[rootNodes.length - 1];
                lastNode.text = `${lastNode.text || ""}${afterCursor}`.trim();
            }

            return rootNodes;
        },
        [currentPage, currentBlock, cursorPosition]
    );

    const insertCustomNodes = useCallback(
        (nodes: CustomNode[]) => {
            if (!grid || nodes.length === 0) {
                return;
            }

            const targetId = nodes[0].id;

            const replaceInParent = (currentNode: CustomNode): CustomNode => {
                const newChildren = currentNode.children.flatMap((child: CustomNode) => {
                    if (child.id === targetId) {
                        const [firstNode, ...restNodes] = nodes;
                        const updatedFirstNode = {
                            ...firstNode,
                            children: [...firstNode.children, ...child.children],
                        };
                        return [updatedFirstNode, ...restNodes];
                    }
                    return [replaceInParent(child)];
                });

                return {...currentNode, children: newChildren};
            };

            let newGrid;
            if (grid.id === targetId) {
                const [firstNode, ...restNodes] = nodes;
                newGrid = {
                    ...grid,
                    children: [
                        {
                            ...firstNode,
                            children: [...firstNode.children, ...grid.children],
                        },
                        ...restNodes,
                    ],
                };
            } else {
                newGrid = replaceInParent(grid);
            }

            if (!newGrid) {
                return;
            }

            dispatch(setCurrentBlock(nodes[0]));
            dispatch(setFirstPastedBlock(nodes[0]));
            dispatch(setGrid(newGrid));
        },
        [grid, dispatch]
    );


    return useCallback(async () => {
        try {
            const text = await navigator.clipboard.readText();
            if (text) {
                const customNodes = transformTextToCustomNodes(text);
                insertCustomNodes(customNodes);
            }
        } catch (error) {
            console.error("Error reading clipboard data:", error);
        }
    }, [transformTextToCustomNodes, insertCustomNodes]);
};

import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {clientWsPage, clientWsPageContent} from "../../api/client-ws";
import {setHasUnsavedChanges} from "../../redux/slices/keeps-slice";
import {CustomNode, Page} from "../../components/types/types";

const useSave = () => {
    const dispatch = useDispatch();

    return useCallback(
        async (currentPageId: string | undefined, updatedGrid: CustomNode, updatedPages: Page) => {
            try {
                if (currentPageId) {
                    await clientWsPageContent.updatePageContent(currentPageId, updatedGrid);
                    await clientWsPage.update(updatedPages);
                    dispatch(setHasUnsavedChanges(false)); // Сбрасываем флаг несохранённых изменений
                    console.log("Grid saved successfully");
                } else {
                    console.error("currentPage.id is undefined");
                }
            } catch (error) {
                console.error("Failed to save grid:", error);
            }
        },
        [dispatch] // Зависимости, чтобы хук обновлялся при изменении `dispatch`
    ); // Возвращаем функцию сохранения
};

export default useSave;

import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import {AuthProvider} from './context/AuthContext';
import {Network} from '@capacitor/network';
import store from "./redux";
import CookieBanner from "./components/molecula/CookieBanner";
import AppRoutes from "./routes/AppRoutes";

Modal.setAppElement('#root');

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration) => console.log('Service Worker зарегистрирован', registration))
            .catch((error) => console.error('Ошибка регистрации Service Worker', error));
    });
}

const App: React.FC = () => {
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        let networkListenerHandle: any;

        const initializeNetworkListener = async () => {
            // Проверяем текущее состояние сети
            const status = await Network.getStatus();
            setIsOnline(status.connected);

            // Подписываемся на изменения состояния сети
            networkListenerHandle = await Network.addListener('networkStatusChange', (status) => {
                console.log('Состояние сети изменилось:', status);
                setIsOnline(status.connected);
            });
        };

        initializeNetworkListener();

        // Очистка слушателя при размонтировании
        return () => {
            if (networkListenerHandle) {
                networkListenerHandle.remove();
            }
        };
    }, []);

    return (
        <Provider store={store}>
            <CookiesProvider>
                <AuthProvider>
                    <CookieBanner/>
                    {!isOnline ? (
                        <p>Вы офлайн 🚫. Некоторые функции недоступны.</p>
                    ) : null}
                    <AppRoutes/>
                </AuthProvider>
            </CookiesProvider>
        </Provider>
    );
}

export default App;

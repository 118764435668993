import {useCallback} from "react";
import {EditorState, TextSelection} from "prosemirror-state";
import {keymap} from "prosemirror-keymap";
import {history} from "prosemirror-history";
import {baseKeymap} from "prosemirror-commands";
import {setCurrentBlock, updateBlock} from "../../redux/slices/keeps-slice";
import {BlockType, CustomNode, NodeType} from "../../components/types/types";
import {EditorView} from "prosemirror-view";
import {useDispatch} from "react-redux";
import {schema as basicSchema} from "prosemirror-schema-basic";
import {DOMParser, Schema} from "prosemirror-model";
import {useConvertToSubBlock} from "./useConvertToSubBlock";
import {useConvertSubBlockToBlock} from "./useConvertSubBlockToBlock";
import {useConvertBlockOnBackspace} from "./useConvertBlockOnBackspace";
import {useAddBlock} from "./useAddBlock";
import {placeholderPlugin} from "../../utils/keeps/proseMirrorPlugins/placeHolderPlugin";
import handleKeyDownPlugin from "../../utils/keeps/proseMirrorPlugins/handleKeyDownPlugin";


const useInitializeEditorState = (
    node: CustomNode,
    setLocalText: (text: (prevText: string) => any) => void,
    viewRef: React.MutableRefObject<EditorView | null>,
) => {
    const dispatch = useDispatch();

    const convertToSubBlock = useConvertToSubBlock();
    const convertToBlock = useConvertSubBlockToBlock();
    const convertBlockOnBackspace = useConvertBlockOnBackspace();
    const addBlock = useAddBlock();

    const createDocumentFromText = (text: string, schema: Schema) => {
        const parser = new window.DOMParser();
        const content = parser.parseFromString(`<p>${text}</p>`, "text/html").body;
        return DOMParser.fromSchema(schema).parse(content);
    };

    return useCallback(() => {
        const handleUpdateBlock = (updatedBlock: CustomNode) => {
            dispatch(updateBlock(updatedBlock));
            dispatch(setCurrentBlock(updatedBlock));
        }

        return EditorState.create({
            schema: basicSchema,
            doc: createDocumentFromText(node.text || "", basicSchema),
            plugins: [
                history(),
                keymap({
                    ...baseKeymap,
                    Enter: () => {
                        setLocalText((prevText) => {
                            const selection = viewRef?.current?.state.selection as TextSelection;
                            let cursor = 0;

                            if (selection.empty && selection.$cursor) {
                                cursor = selection.$cursor.pos - 1;
                            }

                            const textBeforeCursor = prevText.slice(0, cursor);
                            const textAfterCursor = prevText.slice(cursor);

                            const originalBlock = {...node, text: textBeforeCursor} as CustomNode;
                            dispatch(updateBlock(originalBlock));

                            if (originalBlock) {
                                addBlock(
                                    {
                                        parentNodeId: node.parentId || '',
                                        currentBlockId: node.id,
                                        blockType: originalBlock.blockType || BlockType.text,
                                        position: "after",
                                        wrapperType: NodeType.COL,
                                        insertBlock: {
                                            ...originalBlock,
                                            text: textAfterCursor,
                                        },
                                    },
                                    originalBlock
                                );
                            }

                            return textBeforeCursor;
                        });
                        return true;
                    },
                    Tab: () => {
                        setLocalText((prevText) => {
                            convertToSubBlock({
                                ...node,
                                text: prevText,
                            });
                            return prevText;
                        });
                        return true;
                    },
                    "Ctrl-Enter": () => {
                        setLocalText((prevText) => {
                            if (node.blockType === BlockType.checkbox) {
                                handleUpdateBlock({...node, text: prevText, checked: !node.checked});
                            }
                            if (node.blockType === BlockType.toggle || node.blockType === BlockType.toggled_checkbox) {
                                handleUpdateBlock({...node, text: prevText, minimized: !node.minimized});
                            }
                            return prevText;
                        });
                        return true;
                    },
                    "Ctrl-shift-D": () => {
                        setLocalText((prevText) => {
                            addBlock({
                                parentNodeId: node.parentId || '',
                                currentBlockId: node.id,
                                blockType: node.blockType || BlockType.text,
                                position: "after",
                                wrapperType: NodeType.COL,
                                insertBlock: node,
                            });
                            return prevText;
                        });
                        return true;
                    },
                    "Shift-Tab": () => {
                        setLocalText((prevText) => {
                            try {
                                convertToBlock({
                                    ...node,
                                    text: prevText,
                                });
                            } catch (error) {
                                console.error((error as { message: string }).message);
                            }
                            return prevText;
                        });
                        return true;
                    },
                    Backspace: (state) => {
                        const {$cursor} = state.selection as TextSelection;
                        if ($cursor && $cursor.pos === 1) {
                            setLocalText((prevText) => {
                                convertBlockOnBackspace({
                                    ...node,
                                    text: prevText,
                                });
                                return prevText;
                            });
                            return true;
                        }
                        return false;
                    },
                }),
                placeholderPlugin("Введите \\ для просмотра меню"),
                handleKeyDownPlugin(setLocalText, viewRef, addBlock, dispatch, node),
            ],
        });
    }, [addBlock, convertBlockOnBackspace, convertToBlock, convertToSubBlock, dispatch, node, setLocalText, viewRef]);
};

export default useInitializeEditorState;

import {useDispatch} from 'react-redux';
import {CustomNode} from "../../components/types/types";
import {
    setCurrentBlock,
    setCursorPosition,
    setHasUnsavedChanges,
    setIsMenuVisible,
    updateBlock
} from "../../redux/slices/keeps-slice";
import {useConvertToCheckBox} from "./useConvertToCheckBox";
import {useConvertToToggle} from "./useConvertToToggle";
import {useConvertToToggledCheckbox} from "./useConvertToToggledCheckbox";
import {useConvertToDivider} from "./useConvertToDivider";

export const useHandlePrefixTextConverter = (
    node: CustomNode,
    setLocalText: (text: string) => void,
) => {
    const dispatch = useDispatch();

    const convertToCheckBox = useConvertToCheckBox();
    const convertToToggle = useConvertToToggle();
    const convertToToggledCheckbox = useConvertToToggledCheckbox();
    const convertToDivider = useConvertToDivider();

    return (text: string) => {
        const handleConversion = (
            regex: RegExp,
            sliceIndex: number,
            convertFunc: (nodeToConvert: CustomNode) => (undefined | CustomNode),
            cursorPosition: number | null = 1
        ) => {
            if (regex.test(text)) {
                const newText = text.slice(sliceIndex).trim();
                setLocalText(newText);
                const updatedBlock = convertFunc({...node, text: newText});

                if (updatedBlock) {
                    dispatch(updateBlock(updatedBlock));
                    dispatch(setCurrentBlock(updatedBlock));
                    dispatch(setCursorPosition(cursorPosition));
                }
                return true;
            }
            return false;
        };

        if (
            handleConversion(/^\[]\s/, 3, convertToCheckBox) ||
            handleConversion(/^>\s/, 2, convertToToggle) ||
            handleConversion(/^>\[]\s|^хъЮ\s|^Юхъ\s|^\[]>\s/, 4, convertToToggledCheckbox) ||
            handleConversion(/^---/, 3, convertToDivider, null)
        ) {
            // Уже обработано, ничего не делаем
        } else if (/^\\/.test(text)) {
            const newText = text.slice(1).trim();
            setLocalText(newText);
            const updatedBlock = {...node, text: newText};

            if (updatedBlock) {
                dispatch(updateBlock(updatedBlock));
                dispatch(setCursorPosition(0));
            }
            dispatch(setIsMenuVisible(true));
        } else if (text !== node.text) {
            setLocalText(text); // Обновляем локальный текст при каждом изменении
            dispatch(setHasUnsavedChanges(true));
        }
    };
};

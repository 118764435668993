import {Plugin, TextSelection} from "prosemirror-state";
import {EditorView} from "prosemirror-view";
import {BlockType, CustomNode, InsertBlockOptions, NodeType} from "../../../components/types/types";
import {updateBlock} from "../../../redux/slices/keeps-slice";
import {Dispatch} from "redux";

const handleKeyDownPlugin = (
    setLocalText: (text: (prevText: string) => any) => void,
    viewRef: React.MutableRefObject<EditorView | null>,
    addBlock: (insertBlockOptions: InsertBlockOptions, currentActualNode?: CustomNode) => void,
    dispatch: Dispatch,
    node: CustomNode
) =>
    new Plugin({
        props: {
            handleDOMEvents: {
                beforeinput(view, event) {
                    if (event.inputType === "insertParagraph") {
                        setLocalText((prevText) => {
                            const selection = viewRef?.current?.state.selection as TextSelection;
                            let cursor = 0;

                            if (selection.empty && selection.$cursor) {
                                cursor = selection.$cursor.pos - 1;
                            }

                            const textBeforeCursor = prevText.slice(0, cursor);
                            const textAfterCursor = prevText.slice(cursor);

                            const originalBlock = {...node, text: textBeforeCursor} as CustomNode;
                            dispatch(updateBlock(originalBlock));

                            if (originalBlock) {
                                addBlock(
                                    {
                                        parentNodeId: node.parentId || '',
                                        currentBlockId: node.id,
                                        blockType: originalBlock.blockType || BlockType.text,
                                        position: "after",
                                        wrapperType: NodeType.COL,
                                        insertBlock: {
                                            ...originalBlock,
                                            text: textAfterCursor,
                                        },
                                    },
                                    originalBlock
                                );
                            }
                            view.dispatch(view.state.tr.setSelection(TextSelection.create(view.state.doc, cursor)));

                            return textBeforeCursor;
                        });
                        return true;
                    }
                    // Обновляем вид редактора
                    return false;
                },
            },
        },
    });

export default handleKeyDownPlugin;

import React, {useEffect, useRef, useState} from "react";
import {TextSelection} from "prosemirror-state";
import {EditorView} from "prosemirror-view";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {
    setCurrentBlock,
    setCursorPosition,
    setHighlightedNodes,
    setIsMenuVisible,
    updateBlock
} from "../../redux/slices/keeps-slice";
import {CustomNode, NodeType} from "../../components/types/types";
import DropdownBlockActions from "./DropdownBlockActions";
import {useHandlePrefixTextConverter} from "../../hooks/keeps/useHandlePrefixTextConverter";
import useInitializeEditorState from "../../hooks/keeps/useInitializeEditorState";

const TextEditor = ({node, handleAddBlock}: {
    node: CustomNode;
    handleAddBlock: (position: "before" | "after", wrapperType: NodeType.ROW | NodeType.COL) => void
}) => {
    const dispatch = useDispatch();
    const editorRef = useRef<HTMLDivElement>(null);
    const viewRef = useRef<EditorView | null>(null);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const cursorPosition = useSelector((state: RootState) => state.keeps.present.position);
    const currentBlock = useSelector((state: RootState) => state.keeps.present.currentBlock);
    const highlightedNodes = useSelector((state: RootState) => state.keeps.present.highlightedNodes);
    const isMenuVisible = useSelector((state: RootState) => state.keeps.present.isMenuVisible);
    const pastedBlock = useSelector((state: RootState) => state.keeps.present.firstPastedBlock);

    const [localText, setLocalText] = useState(node.text || "");

    const [isEditorActive, setIsEditorActive] = useState(false);

    const handlePrefixTextConverter = useHandlePrefixTextConverter(node, setLocalText);
    const initializeEditorState = useInitializeEditorState(node, setLocalText, viewRef);

    useEffect(() => {
        if (!editorRef.current) return;
        const state = initializeEditorState();
        const view = new EditorView(editorRef.current, {
            state,
            dispatchTransaction: (transaction) => {
                const newState = view.state.apply(transaction);
                view.updateState(newState);
                handlePrefixTextConverter(newState.doc.textContent);
            },
        });
        viewRef.current = view;
        return () => {
            view.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node, handleAddBlock, dispatch, isMenuVisible, isEditorActive]);

    useEffect(() => {
        if (currentBlock?.id === node.id &&
            viewRef.current &&
            cursorPosition !== null
        ) {
            if (timerRef.current) {
                clearTimeout(timerRef.current); // Очистить предыдущий таймер
            }
            timerRef.current = setTimeout(() => {
                const view = viewRef.current!;
                view.focus();
                const docSize = view.state.doc.content.size;

                if (cursorPosition >= 0 && cursorPosition <= docSize) {
                    view.dispatch(
                        view.state.tr.setSelection(TextSelection.create(view.state.doc, cursorPosition))
                    );
                }
            }, 0);
        }

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current); // Убедиться, что таймер очищен при размонтировании
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBlock, node.id, isMenuVisible]);

    useEffect(() => {
        if (highlightedNodes) {
            viewRef.current?.dom.blur();
        }
    }, [highlightedNodes]);

    useEffect(() => {
        if (currentBlock?.id === node.id) {
            setIsEditorActive(true);
            setLocalText(node.text || currentBlock?.text || "");
        }
        // eslint-disable-next-line
    }, [currentBlock?.id, node.id, cursorPosition]);

    const handleBlur = () => {
        setIsEditorActive(false);
        if (localText !== node.text) {
            if (pastedBlock) {
                // dispatch(updateBlock(pastedBlock)); // если мы делаем вставку то мы не обновляем иначе будет ошибка и сотрутся дети
                // возможно неправильно задается firstPastedBlock и из-за этого проблемы 24.12.2024
            } else {
                dispatch(updateBlock({...node, text: localText}));
            }
            const selection = viewRef.current!.state.selection as TextSelection;
            if (selection.empty && selection.$cursor) {
                if (pastedBlock && pastedBlock.text) {
                    dispatch(setCursorPosition(selection.$cursor.pos + pastedBlock?.text.length - localText.length || 0));
                } else {
                    dispatch(setCursorPosition(selection.$cursor.pos));
                }
            }
        }
    };

    const handleOnFocus = (event: React.MouseEvent<HTMLDivElement>) => {
        dispatch(setCurrentBlock(node));
        dispatch(setHighlightedNodes(null));
        dispatch(setIsMenuVisible(false));
        if (viewRef.current) {
            const view = viewRef.current;
            const pos = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
            });
            if (pos) {
                dispatch(setCursorPosition(pos.pos));
            } else {
                // Если позиция не определена, сбрасываем в начало текста
                dispatch(setCursorPosition(0));
            }
        }
    };

    const handleActivateEditor = () => {
        setIsEditorActive(true);
    };

    const handleDeActivateEditor = () => {
        if (currentBlock?.id !== node.id) {
            setIsEditorActive(false);
        }
    };


    return <>
        {isEditorActive || currentBlock?.id === node.id ? (
            <div
                ref={editorRef}
                onMouseDown={handleOnFocus}
                onBlur={handleBlur}
                onMouseLeave={handleDeActivateEditor}
                className="1bg-yellow-100 w-full cursor-text"
            />
        ) : (
            <div
                onMouseEnter={handleActivateEditor}
                className="w-full cursor-pointer break-words"
                dangerouslySetInnerHTML={{__html: node.text || "\u00A0"}}
            />
        )}
        {isMenuVisible && currentBlock?.id === node.id && <DropdownBlockActions/>}
    </>
};

export default React.memo(TextEditor);
import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import RenderNode from "../../../utils/keeps/RenderNode";
import {RootState} from "../../../redux";
import {useAddSubBlock} from "../../../hooks/keeps/useAddSubBlock";
import {useDeleteBlock} from "../../../hooks/keeps/useDeleteBlock";
import {useAddBlock} from "../../../hooks/keeps/useAddBlock";
import {useMoveBlock} from "../../../hooks/keeps/useMoveBlock";
import {ActionCreators} from "redux-undo"; // Импортируем экшены undo/redo
import {clientWsPage, clientWsPageContent} from "../../../api/client-ws";
import {setCurrentPage, setGrid, setIsMenuVisible, setPages} from "../../../redux/slices/keeps-slice";
import getDefaultGrid from "../../../utils/keeps/getDefaultGrid";
import {useLocation} from "react-router-dom";
import RenderKeepsTitle from "../../../utils/keeps/RenderKeepsTitle";
import {useHeader} from "../../../context/HeaderContext";
import {Helmet} from "react-helmet";
import useSave from "../../../hooks/keeps/useSave";
import {Capacitor} from "@capacitor/core";
import MobileBlockMenu from "../../molecula/keeps/MobileBlockMenu";
import {createPortal} from "react-dom";

const Keeps2: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {setHeaderContent} = useHeader();
    const save = useSave();


    // Берём `present` из состояния, чтобы использовать текущую версию grid
    const grid = useSelector((state: RootState) => state.keeps.present.grid, shallowEqual);
    const currentPage = useSelector((state: RootState) => state.keeps.present.currentPage);
    const pages = useSelector((state: RootState) => state.keeps.present.pages)
    const isMenuVisible = useSelector((state: RootState) => state.keeps.present.isMenuVisible);


    useEffect(() => {
        setHeaderContent('');

        const fetchRootPage = async () => {
            try {
                const root = await clientWsPage.getRootPage();
                dispatch(setPages(root.data));
            } catch (error) {
                console.error('Ошибка при получении Root Page:', error);
            }
        };

        fetchRootPage();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const currentPageId = location.pathname.split('/').pop();
        if (currentPageId && pages) {
            const selectedPage = pages.pages?.find(page => page.id === currentPageId) || null;
            dispatch(setCurrentPage(selectedPage));
        }
    }, [location.pathname, pages, dispatch]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Проверяем, что нажата клавиша Ctrl
            if (event.ctrlKey && event.key.toLowerCase() === "z") {
                event.preventDefault(); // Останавливаем стандартное поведение

                if (event.shiftKey) {
                    // Обработка Ctrl+Shift+Z (повтор действия)
                    dispatch(ActionCreators.redo())
                } else {
                    // Обработка Ctrl+Z (отмена действия)
                    dispatch(ActionCreators.undo())
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
        //eslint-disable-next-line
    }, []); // Пустой массив зависимостей, если нет использования внешних переменных


    useEffect(() => {
        return () => {
            if (currentPage?.id && grid && pages) {
                if (grid.pageId !== currentPage?.id) {
                    return;
                }
                save(currentPage?.id, grid, pages);
            }
            if (isMenuVisible) {
                dispatch(setIsMenuVisible(false));
            }
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchPageContent = async () => {
            if (!currentPage?.id) return;

            try {
                const content = await clientWsPageContent.getPageContentById(currentPage.id);
                if (content.data.id === undefined) {
                    dispatch(setGrid(getDefaultGrid(currentPage.id)));
                } else {
                    dispatch(setGrid(content.data));
                }
            } catch (error) {
                console.error("Failed to load page content:", error);
            }
        };

        fetchPageContent();
        //eslint-disable-next-line
    }, [currentPage?.id, dispatch]);

    const addSubBlock = useAddSubBlock();
    const deleteBlock = useDeleteBlock();
    const addBlock = useAddBlock();
    const moveBlock = useMoveBlock();


    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            const platform = Capacitor.getPlatform();
            if (platform === 'ios' || platform === 'android') {
                setIsMobile(true);
            }
        };

        checkMobile(); // Установим изначальное состояние
    }, []);

    return (
        <div className="min-h-screen max-w-5xl mx-auto bg-white shadow-md rounded-md p-4">
            <Helmet>
                <title>{currentPage?.title || ''} | ChatHolder</title>
            </Helmet>
            <header className="mb-6 flex justify-between items-center space-x-4">
                <RenderKeepsTitle/>
                <button
                    onClick={() => dispatch(ActionCreators.undo())}
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md shadow hover:bg-gray-300"
                >
                    Отменить
                </button>
                <button
                    onClick={() => dispatch(ActionCreators.redo())}
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md shadow hover:bg-gray-300"
                >
                    Повторить
                </button>
            </header>
            {grid && (<>
                    <RenderNode
                        node={grid}
                        addSubBlock={addSubBlock}
                        deleteBlock={deleteBlock}
                        addBlock={addBlock}
                        moveBlock={moveBlock}
                    />
                    {isMobile ? createPortal(<MobileBlockMenu/>, document.body) : null}
                </>
            )}
        </div>
    );
};

export default Keeps2;
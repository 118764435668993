import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import undoable, {excludeAction} from 'redux-undo';
import {CustomNode, Page} from "../../components/types/types";

interface KeepsState {
    grid: CustomNode | null;
    currentBlock: CustomNode | null;
    firstPastedBlock: CustomNode | null;
    nodes: CustomNode[] | null;
    position: number | null;
    highlightedNodes: CustomNode[] | null;
    pages: Page | null;
    currentPage: Page | null;
    dragging: boolean;
    hasUnsavedChanges: boolean;
    isMenuVisible: boolean;
}

const initialState: KeepsState = {
    grid: null,
    currentBlock: null,
    firstPastedBlock: null,
    nodes: null,
    position: null,
    highlightedNodes: null,
    pages: null,
    currentPage: null,
    dragging: false,
    hasUnsavedChanges: false,
    isMenuVisible: false,
};

const KeepsSlice = createSlice({
    name: 'keeps',
    initialState,
    reducers: {
        setGrid(state, action: PayloadAction<CustomNode>) {
            state.grid = action.payload;
            state.hasUnsavedChanges = true;
        },
        setCurrentBlock(state, action: PayloadAction<CustomNode | null>) {
            state.currentBlock = action.payload;
        },
        setFirstPastedBlock(state, action: PayloadAction<CustomNode | null>) {
            state.firstPastedBlock = action.payload;
        },
        updateBlock(state, action: PayloadAction<CustomNode | CustomNode[]>) {
            const updateNodeRecursively = (node: CustomNode, updates: Record<string, CustomNode>): CustomNode => {
                const updatedNode = updates[node.id];
                if (updatedNode) {
                    node = updatedNode;
                }
                if (node.children) {
                    const updatedChildren = node.children.map((child) => updateNodeRecursively(child, updates));
                    return {...node, children: updatedChildren};
                }

                return node;
            };

            const updatesArray = Array.isArray(action.payload) ? action.payload : [action.payload];
            const updatesMap = updatesArray.reduce<Record<string, CustomNode>>((acc, node) => {
                acc[node.id] = node;
                return acc;
            }, {});

            if (state.grid) {
                state.grid = updateNodeRecursively(state.grid, updatesMap);
            }

            state.hasUnsavedChanges = true;
            state.firstPastedBlock = null;
        },
        updatePages(state, action: PayloadAction<Page | Page[]>) {
            const updateNodeRecursively = (page: Page, updates: Record<string, Page>): Page => {
                const updatedPage = updates[page.id] ? {...page, ...updates[page.id]} : page;

                if (updatedPage.pages) {
                    updatedPage.pages = updatedPage.pages.map((child) =>
                        updateNodeRecursively(child, updates)
                    );
                }

                return updatedPage;
            };

            const updatesArray = Array.isArray(action.payload) ? action.payload : [action.payload];
            const updatesMap = updatesArray.reduce<Record<string, Page>>((acc, page) => {
                acc[page.id] = page;
                return acc;
            }, {});

            if (state.pages) {
                state.pages = updateNodeRecursively(state.pages, updatesMap);
            }

            if (state.currentPage !== action.payload) {
                state.hasUnsavedChanges = true;
            }
        },
        setCursorPosition: (state, action: PayloadAction<number | null>) => {
            state.position = action.payload;
        },
        setHighlightedNodes: (state, action: PayloadAction<CustomNode[] | null>) => {
            state.highlightedNodes = action.payload;
        },
        setPages: (state, action: PayloadAction<Page | null>) => {
            state.pages = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<Page | null>) => {
            state.currentPage = action.payload;
        },
        setDragging: (state, action: PayloadAction<boolean>) => {
            state.dragging = action.payload;
        },
        setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
            state.hasUnsavedChanges = action.payload;
        },
        setIsMenuVisible: (state, action: PayloadAction<boolean>) => {
            state.isMenuVisible = action.payload;
        }
    },
});

export const {
    setGrid,
    updateBlock,
    updatePages,
    setCurrentBlock,
    setFirstPastedBlock,
    setCursorPosition,
    setHighlightedNodes,
    setPages,
    setCurrentPage,
    setDragging,
    setHasUnsavedChanges,
    setIsMenuVisible,
} = KeepsSlice.actions;

// Добавляем undoable с исключением определённых действий
const undoableKeepsReducer = undoable(KeepsSlice.reducer, {
    limit: 50, // Максимальное количество действий в истории
    filter: excludeAction([
        'keeps/updateBlock',
        'keeps/setCurrentBlock',
        'keeps/setCursorPosition',
        'keeps/setHighlightedNodes',
        'keeps/setPages',
        'keeps/setCurrentPage',
        'keeps/setDragging',
        'keeps/setHasUnsavedChanges',
        'keeps/setIsMenuVisible',
    ]),
});

export default undoableKeepsReducer;

import {BlockType, CustomNode} from "../../components/types/types";

export const useConvertToTextBlock = () => {
    return (nodeToConvert: CustomNode) => {
        if (!nodeToConvert) {
            console.error("Текущий блок не найден");
            return;
        }

        if (nodeToConvert.blockType === BlockType.text) {
            console.warn("Блок уже является Text");
            return;
        }

        const updatedBlock: CustomNode = {
            ...nodeToConvert,
            blockType: BlockType.text,
        };

        return updatedBlock;
    };
};

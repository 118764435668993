import React, {useCallback, useEffect, useState} from 'react';
import SuccessStep from '../SuccessStep';
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import ButtonStep from "../ButtonStep";
import {clientWsVkChannel} from "../../../../api/client-ws";

const VkConnectionSteps: React.FC = () => {
    const [authStatus, setAuthStatus] = useState<string>('INIT');

    const newConnectionId = useSelector((state: RootState) => state.connections.newConnectionId);
    
    const checkAuthStatus = useCallback(async () => {
        if (!newConnectionId) return;
        try {
            const response = await clientWsVkChannel.get(newConnectionId);
            setAuthStatus(response.data.status);
        } catch (error) {
            console.error('Error checking Telegram channel status:', error);
        }
    }, [newConnectionId]);

    useEffect(() => {
        if (authStatus === 'INIT') {
            const authStatusInterval = setInterval(checkAuthStatus, 5000); // Интервал для проверки статуса
            return () => clearInterval(authStatusInterval);
        }
    }, [authStatus, checkAuthStatus]);

    if (authStatus === 'ACTIVE') {
        return <SuccessStep/>;
    }

    return (
        <>
            {!newConnectionId && <ButtonStep buttonName={`Подключить VK`}/>}
        </>
    );
};

export default VkConnectionSteps;

import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {setCurrentBlock, setHasUnsavedChanges, setHighlightedNodes, updatePages} from "../../redux/slices/keeps-slice";
import {debounce} from "lodash";
import useSave from "../../hooks/keeps/useSave";

const RenderKeepsTitle: React.FC = () => {
    const dispatch = useDispatch();

    const currentPage = useSelector((state: RootState) => state.keeps.present.currentPage);

    const [localTitle, setLocalTitle] = React.useState(currentPage?.title || "");

    const save = useSave();
    const grid = useSelector((state: RootState) => state.keeps.present.grid, shallowEqual);
    const pages = useSelector((state: RootState) => state.keeps.present.pages)

    useEffect(() => {
        setLocalTitle(currentPage?.title || "");
    }, [currentPage]);

    const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocalTitle(e.target.value)
        dispatch(setHasUnsavedChanges(true));
    };

    const handleOnFocus = () => {
        dispatch(setCurrentBlock(null));
        dispatch(setHighlightedNodes(null));
    };

    const handleOnBlur = () => {
        if (currentPage) {
            dispatch(updatePages({...currentPage, title: localTitle}));
        }
    };

    // Дебаунс-функция для сохранения
    const saveDebounced = debounce(async (updatedGrid, updatedPages) => {
        save(currentPage?.id, updatedGrid, updatedPages);
    }, 7000);

    const hasUnsavedChanges = useSelector((state: RootState) => state.keeps.present.hasUnsavedChanges);


    useEffect(() => {
        // Сохраняем grid при изменении
        if (hasUnsavedChanges) {
            saveDebounced(grid, pages);
        }

        return () => {
            saveDebounced.cancel(); // Очищаем debounce при размонтировании
        };
        // eslint-disable-next-line
    }, [grid, pages, hasUnsavedChanges]);

    return (
        <textarea
            value={localTitle}
            onChange={handleTitleChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            className="w-full text-4xl font-bold text-left text-slate-800 bg-transparent resize-none focus:outline-none 1bg-yellow-500"
            placeholder="Введите заголовок страницы"
        />
    );
};

export default RenderKeepsTitle;

import React, {useRef} from 'react';
import {useConvertToCheckBox} from "../../hooks/keeps/useConvertToCheckBox";
import {useConvertToToggle} from "../../hooks/keeps/useConvertToToggle";
import {useConvertToToggledCheckbox} from "../../hooks/keeps/useConvertToToggledCheckbox";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {
    setCurrentBlock,
    setCursorPosition,
    setHighlightedNodes,
    setIsMenuVisible,
    updateBlock
} from "../../redux/slices/keeps-slice";
import {CustomNode} from "../../components/types/types";
import {useDeleteBlock} from "../../hooks/keeps/useDeleteBlock";
import {useConvertToTextBlock} from "../../hooks/keeps/useConvertToTextBlock";
import Tooltip from "../../components/atom/Tooltip";
import {useConvertToDivider} from "../../hooks/keeps/useConvertToDivider";

const DropdownBlockActions = () => {
    const dispatch = useDispatch();
    const currentBlock = useSelector((state: RootState) => state.keeps.present.currentBlock);

    const menuRef = useRef<HTMLDivElement>(null);

    const convertToCheckBox = useConvertToCheckBox();
    const convertToToggle = useConvertToToggle();
    const convertToText = useConvertToTextBlock();
    const convertToToggledCheckbox = useConvertToToggledCheckbox();
    const convertToDivider = useConvertToDivider();
    const deleteBlock = useDeleteBlock();

    if (!currentBlock) {
        console.error('Текущий блок не выбран');
        return null;
    }

    const handleConvertToText = () => {
        const updatedBlock = convertToText(currentBlock as CustomNode);
        if (updatedBlock) {
            dispatch(updateBlock(updatedBlock));
            dispatch(setCursorPosition(1));
            dispatch(setIsMenuVisible(false));
        }
    };

    const handleConvertToCheckBox = () => {
        const updatedBlock = convertToCheckBox(currentBlock as CustomNode);
        if (updatedBlock) {
            dispatch(updateBlock(updatedBlock));
            dispatch(setCursorPosition(1));
            dispatch(setIsMenuVisible(false));
        }
    };

    const handleConvertToToggle = () => {
        const updatedBlock = convertToToggle(currentBlock as CustomNode);
        if (updatedBlock) {
            dispatch(updateBlock(updatedBlock));
            dispatch(setCursorPosition(1));
            dispatch(setIsMenuVisible(false));
        }
    };

    const handleConvertToToggledCheckbox = () => {
        const updatedBlock = convertToToggledCheckbox(currentBlock as CustomNode);
        if (updatedBlock) {
            dispatch(updateBlock(updatedBlock));
            dispatch(setCursorPosition(1));
            dispatch(setIsMenuVisible(false));
        }
    };

    const handleSelectBlock = () => {
        if (currentBlock) {
            dispatch(setHighlightedNodes([currentBlock]));
            dispatch(setCursorPosition(null));
            dispatch(setIsMenuVisible(false));
        }
    };

    const handleDeleteBlock = () => {
        if (currentBlock) {
            deleteBlock(currentBlock.id);
            dispatch(setCursorPosition(null));
            dispatch(setIsMenuVisible(false));
        }
    };

    const handleConvertToDivider = () => {
        const updatedBlock = convertToDivider(currentBlock as CustomNode);
        if (updatedBlock) {
            dispatch(updateBlock(updatedBlock));
            dispatch(setCursorPosition(null));
            dispatch(setCurrentBlock(null))
            dispatch(setIsMenuVisible(false));
        }
    };

    const actionItems = [
        {text: 'Текст', hint: `Текстовый блок`, rounded: 'rounded-t-lg', onClick: handleConvertToText},
        {text: 'Разделитель', hint: `Разделитель`, rounded: 'rounded-t-lg', onClick: handleConvertToDivider},
        {text: 'Чекбокс', hint: `Добавьте [] в начало текста`, onClick: handleConvertToCheckBox},
        {text: 'Раскрывающийся список', hint: `Добавьте > в начало текста`, onClick: handleConvertToToggle},
        {
            text: 'Раскрывающийся список с чекбоксом',
            hint: "Добавьте >[] в начало текста",
            onClick: handleConvertToToggledCheckbox
        },
        {text: 'Выделить блок', hint: `Нажмите ESC`, onClick: handleSelectBlock},
        {
            text: 'Удалить блок',
            hint: `Нажмите Delete когда блок выделен`,
            rounded: 'rounded-b-lg',
            onClick: handleDeleteBlock
        },
    ];

    return (
        <div
            ref={menuRef}
            // style={{top: position.top, left: position.left}}
            className="absolute top-8 left-2 w-64 bg-white rounded-md shadow-lg z-50"
        >
            {actionItems.map(({text, hint, rounded, onClick}, index) => (
                <Tooltip text={hint} key={index}>
                    <div
                        className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${rounded || ''}`}
                        onClick={onClick}
                    >
                        <span>{text}</span>
                    </div>
                </Tooltip>
            ))}
        </div>
    );
};

export default DropdownBlockActions;

type MessageStatus = "IN_PROGRESS" | "DELIVERED" | "ERROR";
type MessageType = "TEXT" | "MEDIA" | "UNKNOWN";
type MessageSubType = "IMAGE" | "VIDEO" | "VOICE" | "TEXT" | "UNKNOWN";

export const enum ChannelType {
    WHATSAPP = "WHATSAPP",
    TELEGRAM = "TELEGRAM",
    AVITO = "AVITO",
    FACEBOOK = "FACEBOOK",
    INSTAGRAM = "INSTAGRAM",
    VK = "VK",
    MULTICHAT = "MULTICHAT",
}

export interface PreviewMessage {
    status: MessageStatus;
    directionType: DirectionType;
    externalContactId: string;
    messageTimestamp: string; // можно использовать Date, если будет преобразование
    body: string;
    messageType: MessageType;
    messageSubType: MessageSubType;
    sentByUserId: string;
    read: boolean;
    readByUserId: string;
    readTimestamp: string; // аналогично, можно использовать Date
}

interface MultiChatSettingsDto {
    joinedChats: ChatInterface[];
    channelIds: string[];
}

export interface ChatInterface {
    chatId: string;
    channelId?: string;
    channelType: ChannelType;
    chatTitle?: string;
    externalContactId?: string;
    unreadMessageId?: string;
    previewMessageId?: string;
    previewMessage?: PreviewMessage;
    multiChatSettings?: MultiChatSettingsDto;
}

export interface Connection {
    channelId: string; // connection на бэкенде назвается channel
    channelType: string;
    title?: string;
    status: ConnectionStatus;
}

export interface MessageInterface {
    messageId: string;
    chatId: string;
    body: string;
    channelId: string | null;
    channelType: string | null;
    directionType: string;
    status: string;
    externalContactId: string;
    messageTimestamp: string;
    messageType: string;
    sentByUserId: null;
    read?: boolean;
    readByUserId?: string;
    readTimestamp?: string;
    mediaFileId: string | null;
}

export interface User {
    id: string;
    name: string;
    email: string;
}

export const enum DirectionType {
    INCOMING = "INCOMING",
    OUTGOING = "OUTGOING",
}

export const enum RoleType {
    OWNER = "OWNER",
    TEAMMATE = "TEAMMATE",
}

export const enum StatusType {
    ACTIVE = "ACTIVE",
}

export enum ConnectionStatus {
    INIT = 'INIT',
    ACTIVE = 'ACTIVE',
    SESSION_TERMINATED = 'SESSION_TERMINATED',
    SESSION_ERROR = 'SESSION_ERROR',
}


export const LIMITS = {
    WHATSAPP: 1,
    TELEGRAM: 1,
    AVITO: 1,
    VK: 1,
    TEAMMATES: 9999,
    FILES: 1,
};

export type AuthStatus = 'INIT' | 'ACTIVE' | 'ERROR'; // TODO переделать на enum

export const enum ModalType {
    EditConnection,
    DeleteConnection,
    ForceSyncContacts,
    TeammatesCreation,
    InboxSettings,
    ConnectionStatus
}

export interface AuthLoginRequest {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface AuthSignupRequest {
    email: string;
    password: string;
    referralCode?: string;
}

export interface TeammatesResponse {
    "userId": string,
    "fullName": string,
    "email": string,
    "registrationDate": string,
}

export interface InvitationLinkResponse {
    "invitationLink": string,
    "created": string,
    "inviteCode": string
}

export interface TeammateWithInvite {
    "teammate"?: TeammatesResponse,
    "invitationLink"?: InvitationLinkResponse,
}

export interface ErrorResponse {
    "status": string,
    "code": string,
    "message": string,
    "errorDetails": string,
    "path": string,
    "timestamp": string,
}

export interface AttachmentResponse {
    contentType: string, // "image/png"
    created: string, //"2024-08-07T13:09:03.562Z"
    fileId: string, // "66b371efed4f2d766e4955d4"
    link: string, // "https://dev.chatholder.ru/66b371efed4f2d766e4955d4"
    name: string, //"photo.png"
    previewData: string, // null
    size: number, //7856
}

declare global {
    interface Window {
        ym: (counterId: number, eventName: string, goal: string) => void;
    }
}


export interface InvoiceResponse {
    accountId: string,
    amount: string,
    currency: string,
    email: string,
    invoiceId: string,
    publicId: string,
}

export interface PlanLimits {
    whatsappChannel: number;
    telegramChannel: number;
    avitoChannel: number;
    filesInAttachment: number;
    teammates: number;
}

export interface CurrentPlanResponse {
    billingPlanType: string; // "PRO"
    nextCycleBillingPlanType: string | null; // null
    price: number; // 2000
    subscriptionStartTimestamp: string; // "2024-10-11T11:19:37.138Z"
    nextBillingCycleEndTimestamp: string; // "2024-11-11T11:19:37.138Z"
    nextPayTimestamp: string; // "2024-11-11T11:19:37.138Z"
    prevBillingCycleEndTimestamp: string; // "2024-10-11T11:19:37.138Z"
    planLimits?: PlanLimits; // теперь это объект с лимитами
}

interface ChatSearchRequest {
    channelId?: string;
    channelType?: ChannelType;
    chatTitle?: string;
    externalContactId?: string;
    status?: ConnectionStatus;
}

export interface FindSingleChatsParams {
    size?: number;
    request?: ChatSearchRequest;
}

export interface SingleAndMultiChatIdsRequest {
    singleChatId: string;
    multiChatId: string;
}

export interface ChatSearchResponse {
    chatId: string;
    channelId: string;
    channelType: ChannelType;
    chatTitle: string;
    externalContactId: string;
    unreadMessageId: string;
    previewMessageId: string;
    previewMessage: PreviewMessage;
}

export enum BlockType {
    text = "text",
    checkbox = "checkbox",
    toggle = "toggle",
    toggled_checkbox = "toggled_checkbox",
    divider = "divider",
}

export enum NodeType {
    BLOCK = "BLOCK",
    ROW = "ROW",
    COL = "COL",
}

export type WrapperPosition = "before" | "after" | "inside";

export interface Pages {
    id: string;
    pages?: Page[];
}

export interface Page {
    id: string;
    title?: string;
    pages?: Page[];
    minimized?: boolean;
}

export interface CustomNode {
    id: string;
    pageId: string; // id страницы где лежит нода
    nodeType: NodeType; // Тип ноды блок, колонка, строка
    parentId?: string | null;
    blockType?: BlockType; // Тип блока текст чекбокс и тп
    children: CustomNode[]; // Вложенные элементы
    checked?: boolean; // Чекбокс
    minimized?: boolean; // Toggle
    text?: string; // Текстовое наполнение
}

export interface InsertBlockOptions {
    parentNodeId: string,
    currentBlockId: string,
    targetBlockId?: string;
    insertBlock?: CustomNode;
    blockType: BlockType,
    position: WrapperPosition;
    wrapperType?: NodeType;
}
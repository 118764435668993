import {Plugin, PluginKey} from "prosemirror-state";
import {Decoration, DecorationSet} from "prosemirror-view";

export const placeholderPlugin = (placeholderText: string) => {
    return new Plugin({
        key: new PluginKey("placeholderPlugin"),
        props: {
            decorations(state) {
                const {doc} = state;
                const decorations: Decoration[] = [];

                // Проверяем, что текст пустой
                const isEmpty = doc.childCount === 1 &&
                    doc.firstChild?.isTextblock &&
                    doc.firstChild.content.size === 0;

                // Проверяем, что редактор имеет фокус
                const hasFocus = state.selection.empty;

                // Условие для отображения плейсхолдера
                if (isEmpty && hasFocus) {
                    decorations.push(
                        Decoration.node(0, doc.content.size, {
                            class: "relative overflow-hidden before:content-[attr(data-placeholder)] before:absolute before:text-gray-400 before:pointer-events-none",
                            "data-placeholder": placeholderText,
                        })
                    );
                }

                return DecorationSet.create(doc, decorations);
            },
        },
    });
};

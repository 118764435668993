import {useDispatch} from "react-redux";
import {BlockType, CustomNode, NodeType} from "../../components/types/types";
import {setCurrentBlock, setCursorPosition, updateBlock} from "../../redux/slices/keeps-slice";
import {useConvertSubBlockToBlock} from "./useConvertSubBlockToBlock";
import {useDeleteBlock} from "./useDeleteBlock";
import {useFindNodeById} from "./useFindNodeById";

export const useConvertBlockOnBackspace = () => {
    const dispatch = useDispatch();
    const convertToBlock = useConvertSubBlockToBlock();
    const deleteBlock = useDeleteBlock();
    const findNodeById = useFindNodeById();

    return (node: CustomNode) => {
        // Проверка типа блока
        switch (node.blockType) {
            case BlockType.checkbox:
            case BlockType.toggled_checkbox:
            case BlockType.toggle: {
                // Преобразование чекбокса в обычный текстовый блок
                const updatedNode = {
                    ...node,
                    blockType: BlockType.text, // Сменяем тип на обычный блок
                };
                dispatch(updateBlock(updatedNode));
                break;
            }
            case BlockType.text: {
                const updatedNode = {
                    ...node,
                    blockType: BlockType.text, // Сменяем тип на обычный блок
                };
                try {
                    if (!node.parentId) return
                    let parent = findNodeById(node.parentId);

                    if (parent && parent.nodeType === NodeType.COL) {
                        // Логика для мержа блока
                        const parentChildren = parent.children || [];
                        const nodeIndex = parentChildren.findIndex((child: CustomNode) => child.id === node.id);

                        // Проверяем, что блок не первый в списке
                        if (nodeIndex > 0) {
                            const previousBlockId = parentChildren[nodeIndex - 1]?.id;
                            const previousBlock = findNodeById(previousBlockId);

                            deleteBlock(node.id);

                            // тут можно сделать проверку на тип предыдущего блока
                            // к примеру если это картинка то обойти его и тп
                            if (previousBlock) {
                                // Обновляем текст предыдущего блока
                                const mergedBlock = {
                                    ...previousBlock,
                                    text: `${previousBlock.text || ''} ${node.text || ''}`.trim(),
                                };

                                dispatch(setCurrentBlock(mergedBlock));
                                dispatch(setCursorPosition((previousBlock.text ?? '').length + 1 || 0));
                                dispatch(updateBlock(mergedBlock));

                                // Удаляем текущий блок
                                return mergedBlock;
                            }
                        }
                        // Если блок первый, просто возвращаем
                        return;
                    }

                    convertToBlock(updatedNode)
                    dispatch(updateBlock(updatedNode));
                } catch (error) {
                    deleteBlock(updatedNode.id);
                }
                break;
            }
            default: {
                console.warn(`useConvertBlockOnBackspace: Unknown block type: ${node.blockType}`);
                break;
            }
        }
    };
};

import React, {useCallback, useEffect, useState} from "react";
import {BlockType, CustomNode, InsertBlockOptions, NodeType, WrapperPosition} from "../../components/types/types";
import DropZones from "./DropZones";
import AddBlockZonesForBlock from "./AddBlockZonesForBlock";
import RenderChildren from "./RenderChildren";
import TextEditor from "./TextEditor";
import {useDispatch, useSelector} from "react-redux";
import {useDeleteBlock} from "../../hooks/keeps/useDeleteBlock";
import {RootState} from "../../redux";
import {setCurrentBlock, setHighlightedNodes, setIsMenuVisible, updateBlock} from "../../redux/slices/keeps-slice";
import Tooltip from "../../components/atom/Tooltip";
import {useHandlePaste} from "../../hooks/keeps/useHandlePaste";
import DropdownBlockActions from "./DropdownBlockActions";
import {Capacitor} from "@capacitor/core";

interface BlockComponentProps {
    node: CustomNode;
    handleAddBlock: (position: WrapperPosition, wrapperType: NodeType.ROW | NodeType.COL) => void;
    handleAddBlockIntoWrapper: (position: WrapperPosition) => void;
    addSubBlock: (insertBlockOptions: InsertBlockOptions) => void;
    deleteBlock: (blockId: string) => void;
    addBlock: (insertBlockOptions: InsertBlockOptions) => void;
    moveBlock: (insertBlockOptions: InsertBlockOptions) => void;
    handleDrop: (e: React.DragEvent, position: "before" | "after" | "inside", wrapperType?: NodeType.ROW | NodeType.COL) => void;
    handleDragStart: (e: React.DragEvent) => void;
    handleDragOver: (e: React.DragEvent) => void;
    handleDragEnd: (e: React.DragEvent) => void;
}

const BlockComponent: React.FC<BlockComponentProps> = ({
                                                           node,
                                                           handleAddBlock,
                                                           addSubBlock,
                                                           addBlock,
                                                           moveBlock,
                                                           handleDrop,
                                                           handleDragOver,
                                                           handleDragStart,
                                                           handleDragEnd,
                                                       }) => {
    const dispatch = useDispatch();

    const highlightedNodes = useSelector((state: RootState) => state.keeps.present.highlightedNodes);
    const currentBlock = useSelector((state: RootState) => state.keeps.present.currentBlock);
    const isMenuVisible = useSelector((state: RootState) => state.keeps.present.isMenuVisible);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            // const userAgent = navigator.userAgent.toLowerCase();
            // setIsMobile(/android|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent));
            const platform = Capacitor.getPlatform();
            if (platform === 'ios' || platform === 'android') {
                setIsMobile(true);
            }
        };

        checkMobile(); // Установим изначальное состояние
    }, []);


    const grid = useSelector((state: RootState) => state.keeps.present.grid);


    const deleteBlock = useDeleteBlock();
    const handlePaste = useHandlePaste();

    const unsetNode = () => {
        dispatch(setHighlightedNodes(null)); // Убираем подсветку
        dispatch(setCurrentBlock(null)); // Сбрасываем текущий блок
    };

    const handleKeyDown = useCallback(async (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            if (highlightedNodes && highlightedNodes.length) {
                console.log("второй esc");
                unsetNode();
            } else if (currentBlock) {
                console.log("первый esc");
                dispatch(setIsMenuVisible(false));
                dispatch(setHighlightedNodes([currentBlock]));
            }
        }
        if (event.key === "Delete" && highlightedNodes) {
            deleteBlock(highlightedNodes[0].id);
            unsetNode();
        }
        if (event.ctrlKey && event.code === 'KeyV') {
            event.preventDefault();
            event.stopPropagation();
            if (currentBlock?.id === node.id) {
                console.log('Ctrl + V pressed');
                await handlePaste();
            }
        }
        // eslint-disable-next-line
    }, [currentBlock, highlightedNodes, node, unsetNode, deleteBlock, grid]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedBlock = {
            ...node,
            checked: e.target.checked,
        };
        dispatch(updateBlock(updatedBlock));
    };

    const handleToggle = () => {
        const updatedBlock = {
            ...node,
            minimized: !node.minimized,
        };
        dispatch(updateBlock(updatedBlock));
    };

    const handleMenuVisible = () => {
        dispatch(setIsMenuVisible(!isMenuVisible));
        dispatch(setCurrentBlock(node));
    };

    const renderBlock = (node: CustomNode) => {
        switch (node.blockType) {
            case BlockType.text:
                return <TextEditor key={node.id} node={node} handleAddBlock={handleAddBlock}/>;
            case BlockType.checkbox:
                return (
                    <>
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={node.checked}
                            onChange={handleCheckboxChange}
                        />
                        <TextEditor key={node.id} node={node} handleAddBlock={handleAddBlock}/>
                    </>
                );
            case BlockType.toggle:
                return (
                    <>
                        <button
                            onClick={handleToggle}
                            className={`transition-transform duration-300 mr-1.5 ${
                                node.minimized ? "rotate-0" : "rotate-90"
                            }`}
                        >
                            {/* Иконка стрелочки */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-4 h-4 -ml-0.5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                        </button>
                        <TextEditor
                            key={node.id}
                            node={node}
                            handleAddBlock={handleAddBlock}
                        />
                    </>
                );
            case BlockType.toggled_checkbox:
                return (
                    <>
                        <button
                            onClick={handleToggle}
                            className={`transition-transform duration-300 mr-1.5 ${
                                node.minimized ? "rotate-0" : "rotate-90"
                            }`}
                        >
                            {/* Иконка стрелочки */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-4 h-4 -ml-0.5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                        </button>
                        <input
                            type="checkbox"
                            className="mr-2"
                            defaultChecked={node.checked}
                            onChange={handleCheckboxChange}
                        />
                        <TextEditor
                            key={node.id}
                            node={node}
                            handleAddBlock={handleAddBlock}
                        />
                    </>
                );
            case BlockType.divider:
                return (
                    <>
                        <div
                            className={`bg-gray-700 hover:bg-gray-500 h-1 rounded-md w-full`}
                            role="separator"
                        />
                        {isMenuVisible && currentBlock?.id === node.id && <DropdownBlockActions/>}
                    </>
                );
            default:
                return <div key={node.id}>Неизвестный тип блока</div>;
        }
    };

    return (
        <>
            <div className={`block relative`}>
                {highlightedNodes && highlightedNodes?.some((highlightedNode) => highlightedNode.id === node.id) && (
                    <>
                        <div
                            className="absolute z-10 inset-0 -top-0.5 bottom-0.5 left-1 right-1 ring-2 ring-sky-200 rounded-lg pointer-events-none"></div>
                    </>
                )}
                <div
                    draggable
                    onDragStart={(e) => {
                        e.dataTransfer.setData("application/json", JSON.stringify(node));
                        handleDragStart(e);
                    }}
                    onDragEnd={handleDragEnd}
                    className={`rounded-md transition flex relative items-center ${BlockType.divider === node.blockType ? "py-2.5 my-2" : "px-2 py-0.5 my-1 mx-1.5 bg-gray-50 hover:bg-gray-200"} cursor-grab`}
                >

                    <div
                        className="absolute -left-5 z-50 w-3 flex items-center justify-center h-full opacity-0 hover:opacity-100">
                        {/* Меню с точечками */}
                        <Tooltip text={`Нажать для меню, тащить для перемещения`}>
                            <div
                                className="flex flex-col items-center justify-center cursor-pointer px-1.5 py-1.5 bg-gray-100 rounded-md"
                                onClick={handleMenuVisible}
                                onMouseDown={(e) => e.stopPropagation()} // Чтобы не мешало drag
                            >
                                <span className="w-1 h-1 bg-gray-500 rounded-full mb-0.5"></span>
                                <span className="w-1 h-1 bg-gray-500 rounded-full mb-0.5"></span>
                                <span className="w-1 h-1 bg-gray-500 rounded-full"></span>
                            </div>
                        </Tooltip>
                    </div>


                    <DropZones handleDragOver={handleDragOver} handleDrop={handleDrop}/>
                    {!isMobile ? <AddBlockZonesForBlock handleAddBlock={handleAddBlock}/> : null}
                    {renderBlock(node)}
                </div>
                <div
                    className={`sub-block flex flex-col ml-4 max-w-full ${
                        node.blockType === BlockType.toggle || node.blockType === BlockType.toggled_checkbox
                            ? `transition-all duration-300 ease-in-out ${
                                node.minimized ? "max-h-0 opacity-0" : "max-h-screen opacity-100"
                            }`
                            : ""
                    }`}
                >
                    <RenderChildren
                        children={node.children}
                        addSubBlock={addSubBlock}
                        deleteBlock={deleteBlock}
                        addBlock={addBlock}
                        moveBlock={moveBlock}
                    />
                </div>
            </div>
        </>
    );
};

export default BlockComponent;

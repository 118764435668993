import React, {lazy, Suspense, useContext, useEffect, useState} from 'react';
import {Navigate, NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import AuthContext from '../../../context/AuthContext';
import {authWsSignin} from '../../../api/auth-ws';
import {handleLogError} from '../../../api/base-api';
import {useProfileHelper} from '../../../hooks/useProfileHelper';
import {ButtonColor, ButtonSize} from "../../atom/Button";
import {InputType} from "../../atom/InputField";

// Lazy-loaded components
const FormWrapper = lazy(() => import('../../atom/FormWrapper'));
const InputField = lazy(() => import('../../atom/InputField'));
const CheckboxField = lazy(() => import('../../atom/ChekboxField'));
const Button = lazy(() => import('../../atom/Button'));
const ErrorMessage = lazy(() => import('../../atom/ErrorMessage'));
const Skeleton = () => (
    <div className="animate-pulse space-y-4">
        <div className="h-10 bg-gray-200 rounded"></div>
        <div className="h-10 bg-gray-200 rounded"></div>
        <div className="h-10 bg-gray-200 rounded"></div>
    </div>
);

const Login: React.FC = () => {
    const authContext = useContext(AuthContext);
    const {loadProfileData} = useProfileHelper();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(authContext?.userIsAuthenticated() ?? false);
    const [isLoadingProfile, setIsLoadingProfile] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isPageLoading, setIsPageLoading] = useState(true);

    useEffect(() => {
        setIsLoggedIn(authContext?.userIsAuthenticated() ?? false);
    }, [authContext]);

    useEffect(() => {
        // Simulating a delay for the page loading (e.g., API call for configurations)
        const timer = setTimeout(() => setIsPageLoading(false), 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, checked} = e.target;
        if (name === 'email') setEmail(value.trim());
        if (name === 'password') setPassword(value.trim());
        if (name === 'rememberMe') setRememberMe(checked);

        setErrors(prev => ({...prev, [name]: ''})); // Clear error on change
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};
        if (!email) newErrors.email = 'Email обязателен';
        if (!password) newErrors.password = 'Пароль обязателен';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateForm()) return;

        try {
            const response = await authWsSignin.signIn({email, password, rememberMe});
            const {token} = response.data;
            authContext?.userLogin(token, rememberMe);

            setIsLoadingProfile(true);
            await loadProfileData();
            setIsLoggedIn(true);
        } catch (error: any) {
            handleLogError(error);
            const serverErrors: { [key: string]: string } = {};
            if (error.response?.data?.message === 'Invalid email or password') {
                serverErrors.form = 'Неправильный email или пароль';
            } else if (error.response?.data?.code === 'WRONG_CREDENTIALS') {
                serverErrors.form = 'Неправильный email или пароль';
            } else {
                serverErrors.form = 'Ошибка при авторизации';
            }
            setErrors(prev => ({...prev, ...serverErrors}));
        } finally {
            setIsLoadingProfile(false);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    if (isLoggedIn && !isLoadingProfile) {
        return <Navigate to="/"/>;
    }

    return (
        <div className="flex justify-center items-center h-screen">
            <Helmet>
                <title>Авторизация | ChatHolder</title>
            </Helmet>
            {isPageLoading ? (
                <Skeleton/>
            ) : (
                <Suspense fallback={<Skeleton/>}>
                    <div className="flex flex-col items-center">
                        <div className="text-center mb-8 w-full max-w-md">
                            <h1 className="sm:text-7xl text-6xl font-bold text-dark mb-4">Chatholder</h1>
                            <p className="sm:text-2xl text-xl text-slate-600">
                                Инновационная платформа для связи через мессенджеры и соц сети
                            </p>
                        </div>

                        <div className="w-full max-w-md">
                            <FormWrapper onSubmit={handleSubmit}>
                                <>
                                    <InputField
                                        type={InputType.EMAIL}
                                        name="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={handleInputChange}
                                        error={errors.email}
                                    />
                                    <InputField
                                        type={showPassword ? InputType.TEXT : InputType.PASSWORD}
                                        name="password"
                                        placeholder="Пароль"
                                        value={password}
                                        onChange={handleInputChange}
                                        showPassword={showPassword}
                                        togglePasswordVisibility={togglePasswordVisibility}
                                        error={errors.password}
                                    />
                                    <CheckboxField
                                        name="rememberMe"
                                        checked={rememberMe}
                                        onChange={handleInputChange}
                                        label="Не выходить при закрытии браузера"
                                    />
                                    <div className="relative">
                                        <Button
                                            title="Авторизоваться"
                                            buttonSize={ButtonSize.FULL}
                                            type="submit"
                                            buttonColor={ButtonColor.GREEN}
                                            customClass="py-6"
                                        />
                                        {errors.form && <ErrorMessage message={errors.form}/>}
                                    </div>
                                </>
                            </FormWrapper>
                        </div>

                        <div className="mt-5 text-center">
              <span className="text-slate-600">
                У вас нет учетной записи?&nbsp;
                  <NavLink to="/signup" className="text-success-green hover:underline">
                  Регистрация
                </NavLink>
              </span>
                        </div>
                    </div>
                </Suspense>
            )}
        </div>
    );
};

export default Login;
